import LogoKDNSelect from "./components/icons/LogoKDNSelect.vue"
import DatePicker from "./components/forms/DatePicker.vue"
import TextInput from "./components/elements/inputs/TextInput.vue"
import TextArea from "./components/elements/inputs/TextArea.vue"
import SearchEmployeeInput from "./components/employees/SearchEmployeeInput.vue"

import PrimaryButton from "./components/elements/buttons/PrimaryButton.vue"
import StepsSidebar from "./components/elements/sidebars/steps-sidebar/Index.vue"
import StepItem from "./components/elements/sidebars/steps-sidebar/StepItem.vue"

import SharedOrderStep2 from "./components/shared-order-approval/Step2.vue"
import SharedOrderStep3 from "./components/shared-order-approval/Step3.vue"
import SharedOrderStep4 from "./components/shared-order-approval/Step4.vue"
import PersonalizeOrderItem from "./components/shared-order-approval/PersonalizeOrderItem.vue"

import AccountManager from "./components/support/AccountManager.vue"
import SupportButton from "./components/support/SupportButton.vue"
import SupportArticles from "./components/support/SupportArticles.vue"

import FadeIn from "./components/transitions/FadeIn.vue"

import DepartmentItem from "./components/company/DepartmentItem.vue"

import CustomizationAd from "./components/sidebar/CustomizationAd.vue"
import ButtonDropdown from "./components/elements/dropdown/ButtonDropdown.vue"


import EmployeesList from "./components/employees-management/EmployeesList.vue"

import ReportCard from "./components/dashboard/reports/ReportCard.vue"

import NewCartItem from "@/components/NewCartItem.vue"

import CreditIcon from "@/components/icons/CreditIcon.vue"

const components = {
  "logo-kdn-select": LogoKDNSelect,
  "text-input": TextInput,
  "kdn-datepicker": DatePicker,
  TextArea,
  ButtonDropdown,
  "kdn-search-employee": SearchEmployeeInput,
  PrimaryButton,
  StepsSidebar,
  StepItem,

  SharedOrderStep2,
  SharedOrderStep3,
  SharedOrderStep4,
  PersonalizeOrderItem,
  CustomizationAd,
  AccountManager,
  SupportButton,
  SupportArticles,
  DepartmentItem,
  ReportCard,
  "kdn-employees-list": EmployeesList,
  NewCartItem,

  // Transitions
  FadeIn,

  // Icons
  CreditIcon,
}

export default components
