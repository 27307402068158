<template>
  <button class="text-sm font-semibold text-primary" @click="add">
    {{ $t("orders.add_employee_manually") }}
  </button>
</template>

<script>
import uuid from "@/helpers/uuid"

export default {
  data() {
    const recipient = {
      from: "",
      to: "",
      price: "",
      email: "",
      message: "",
      delivery_date: "",
      delivery_time: "",
      locale: "",
      detailed_reference: "",
      thankyou_video: null,
      street: "",
      street_number: "",
      bus: "",
      zipcode: "",
      city: "",
      country: "",
      metadata: {},
    }

    return {
      recipient,
    }
  },
  methods: {
    clear() {
      this.recipient = {
        from: "",
        to: "",
        price: "",
        email: "",
        message: "",
        delivery_date: "",
        delivery_time: "",
        locale: "",
        detailed_reference: "",
        thankyou_video: null,
        street: "",
        street_number: "",
        bus: "",
        zipcode: "",
        city: "",
        country: "",
        metadata: {},
      }
    },
    add() {
      if (this.adding) return

      this.adding = true
      this.$store.commit("recipients/addRecipient", {
        ...this.recipient,
        added_manually: true,
        excel: this.isExcel,
        id: uuid(),
      })
      this.$emit("recipient-added")
      this.clear()
      this.adding = false
    },
  },
}
</script>
