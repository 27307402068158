<template>
  <form class="px-6" @submit.prevent="sendMessage">
    <p class="py-2 text-base font-bold">
      {{ $t("general.send_message") }}
    </p>

    <div v-if="showingSuccessMessage" class="mt-2 mb-4 bg-green-100 text-sm rounded px-4 py-4">
      {{ $t("support.message_sent_successfully") }}
    </div>

    <TextInput
      v-model="name"
      name="name"
      :label="$t('forms.full_name')"
      :error="errors.name ? errors.name[0] : false"
      required
    />

    <TextInput
      v-model="email"
      :label="$t('general.email')"
      :error="errors.email ? errors.email[0] : false"
      required
    />

    <TextArea
      v-model="message"
      :label="$t('general.message')"
      rows="5"
      :error="errors.message ? errors.message[0] : false"
      required
    />

    <div class="my-4 flex justify-end">
      <PrimaryButton type="submit" :busy="working">{{ $t("general.send_message") }}</PrimaryButton>
    </div>
  </form>
</template>

<script>
import httpClient from "@/helpers/http"
import TextInput from "@/components/elements/inputs/TextInput.vue"
import PrimaryButton from "@/components/elements/buttons/PrimaryButton.vue"
import TextArea from "@/components/elements/inputs/TextArea.vue"

export default {
  components: {
    TextInput,
    TextArea,
    PrimaryButton,
  },
  props: { orderId: Number },

  data() {
    return {
      name: "",
      email: "",
      message: "",
      working: false,
      errors: [],
      showingSuccessMessage: false,
    }
  },

  beforeUnmount() {
    this.showingSuccessMessage = false
  },

  methods: {
    sendMessage() {
      this.working = true
      httpClient
        .post(`/support-messages`, {
          name: this.name,
          email: this.email,
          message: this.message,
          orderId: this.orderId,
        })
        .then((/*response*/) => {
          this.showingSuccessMessage = true
          this.resetForm()
          console.log("done")
        })
        .catch(err => {
          if (err.response?.status === 422) {
            this.errors = err.response.data.errors
          }
        })
        .finally(() => (this.working = false))
    },

    resetForm() {
      this.name = ""
      this.email = ""
      this.message = ""
    },
  },
}
</script>
