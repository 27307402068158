<template>
  <form action="" method="post" enctype="multipart/form-data" @submit.prevent="submitForm">
    <div class="mb-15">
      <step-label>{{ $t("shared-order-approval.step") }} 4</step-label>
      <h1 class="font-title font-extrabold text-black text-2xl leading-tight mb-2">
        {{ $t("orders.order_being_shipped") }}
      </h1>
      <p class="text-sm text-gray-400">
        {{ $t("orders.take_look_at_all_orders") }}
      </p>
    </div>

    <div class="mt-10">
      <div class="bg-white rounded border border-blue-200">
        <div class="relative">
          <div class="px-8 py-8">
            <dl class="w-1/2 grid grid-cols-2 gap-x-8 gap-y-6">
              <div>
                <dt class="text-gray-300 uppercase text-2xs font-bold tracking-wider">
                  {{ $t("forms.order-id") }}
                </dt>
                <dd class="text-gray-400 text-sm font-medium">#230492</dd>
              </div>
              <div>
                <dt class="text-gray-300 uppercase text-2xs font-bold tracking-wider">
                  {{ $t("general.total_value") }}
                </dt>
                <dd class="text-gray-400 text-sm font-medium">€ 360,00</dd>
              </div>
              <div class="col-span-2">
                <dt class="text-gray-300 uppercase text-2xs font-bold tracking-wider">
                  {{ $t("general.delivery_date") }}
                </dt>
                <dd class="text-gray-400 text-sm font-medium">24/08/2021</dd>
              </div>
            </dl>
            <div class="mt-6 space-x-4">
              <PrimaryButton compact>
                {{ $t("orders.track_shipment") }}
              </PrimaryButton>
              <TertiaryButton compact>
                {{ $t("shared-order-approval.['step-2'].view_quotation") }}
              </TertiaryButton>
            </div>
          </div>

          <div class="absolute inset-0 overflow-hidden pointer-events-none">
            <div class="relative h-full">
              <img class="absolute inset-y-0 right-0 h-full" :src="rectangleIcon" alt="Separator" />
              <div class="absolute -right-64">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 350 20"
                  class="transform rotate-30 text-white"
                >
                  <path d="M0 10h350" stroke="currentColor" stroke-dasharray="10, 10" />
                </svg>
              </div>
            </div>
          </div>
          <div class="absolute right-32 -top-16">
            <img :src="deliveryTruckIcon" alt="Truck" />
          </div>
        </div>
      </div>
    </div>

    <div class="mt-10">
      <bordered-title class="text-xs">
        Gordunakaai 61, 9000 Gent
        <template #action>
          <div class="relative z-10 bg-gray-100 pl-4">
            <PrimaryButton compact>
              {{ $t("orders.track_shipment") }}
            </PrimaryButton>
          </div>
        </template>
      </bordered-title>
      <div class="divide-y">
        <OrderItem data-only />
        <OrderItem data-only />
      </div>
    </div>

    <div class="mt-10">
      <div class="flex justify-between items-center bg-gray-200 bg-opacity-50 py-6 px-10 rounded">
        <div class="grid grid-cols-2 gap-x-4">
          <p class="text-sm font-semibold">
            <span class="uppercase text-xs text-gray-300 font-bold">
              {{ $t("orders.invoiced_to") }}
            </span>
            <br />
            KDN NV <br />
            BE0666.820.362
          </p>
          <p class="text-sm font-semibold">
            Gordunakaai 61 <br />
            9000 Gent <br />
            Belgium
          </p>
        </div>

        <div class="text-right">
          <p class="flex items-center justify-between">
            <span class="flex-1 text-sm whitespace-nowrap">
              {{ $t("quotation.total") }}
            </span>
            <span class="w-32 font-bold font-title whitespace-nowrap">€ 340,00</span>
          </p>
          <p class="flex items-center justify-between">
            <span class="flex-1 text-sm whitespace-nowrap">
              {{ $t("orders.total_handling_fee") }}
            </span>
            <span class="w-32 font-bold font-title whitespace-nowrap">€ 20,00</span>
          </p>
          <p class="flex items-center justify-between">
            <span class="flex-1 capitalize text-sm whitespace-nowrap">{{
              $t("general.grand_total")
            }}</span>
            <span class="w-32 font-bold font-title whitespace-nowrap text-pink-300">€ 360,00</span>
          </p>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import StepLabel from "../elements/text/StepLabel.vue"
import BorderedTitle from "../elements/text/titles/BorderedTitle.vue"
import PrimaryButton from "../elements/buttons/PrimaryButton.vue"
import TertiaryButton from "../elements/buttons/TertiaryButton.vue"
import OrderItem from "./elements/OrderItem.vue"
import rectangleIcon from "../../../assets/images/rectangle.svg"
import deliveryTruckIcon from "../../../assets/images/delivery-truck.svg"

export default {
  components: {
    StepLabel,
    BorderedTitle,
    OrderItem,
    TertiaryButton,
    PrimaryButton,
  },
  data() {
    return {
      personalize: false,
      rectangleIcon,
      deliveryTruckIcon,
    }
  },
}
</script>
