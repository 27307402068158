import { mapMutations } from "vuex"

const ResolvesSharedData = {
  props: {
    data: Object,
    step: Number,
    settings: Object,
    locales: Array,
    countries: Array,
    deadlineDays: Object,
  },

  created() {
    this.setState({ attribute: "settings", value: this.settings })
    this.setState({ attribute: "locales", value: this.locales })
    this.$store.commit("product/setSettings", this.settings)
    this.$store.commit("product/setCountries", this.countries)
  },

  methods: {
    ...mapMutations("sharedOrder", ["setState"]),
  },
}

export default ResolvesSharedData
