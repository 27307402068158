<template>
  <SelectMultiple
    id="recipient-teams"
    :model-value="selectedTeams"
    :placeholder="$t('general.team')"
    static-placeholder
    :options="groups"
  >
    <template #beforeOptions>
      <ActionLink @click="selectAllTeams" class="mb-2 ml-2">
        {{ $t("general.add_all_teams") }}
      </ActionLink>
    </template>
    <template #option="{ option }">
      <span @click="toggleTeam(option)">
        {{ option.name }}
      </span>
    </template>
  </SelectMultiple>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex"
import SelectMultiple from "../elements/inputs/SelectMultiple.vue"
import ActionLink from "@/components/elements/links/ActionLink.vue"

export default {
  components: {
    ActionLink,
    SelectMultiple,
  },
  computed: {
    ...mapState("recipients", ["beingAdded", "selectedTeams"]),
    ...mapGetters("product", ["groups"]),
  },
  methods: {
    ...mapMutations("recipients", ["removeTeams"]),
    ...mapActions("recipients", ["addTeams"]),
    selectAllTeams() {
      this.$store.commit(
        "recipients/setSelectedTeams",
        this.groups.map(group => group.id),
      )
      this.addTeams(this.selectedTeams)
    },
    toggleTeam(team) {
      if (this.selectedTeams.includes(team.id)) {
        this.removeTeams([team.id])
        this.$store.commit(
          "recipients/setSelectedTeams",
          this.selectedTeams.filter(id => id !== team.id),
        )
      } else {
        this.addTeams([team.id])
        this.$store.commit("recipients/setSelectedTeams", [...this.selectedTeams, team.id])
      }
    },
  },
}
</script>
