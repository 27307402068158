<template>
  <div>
    <div class="flex border rounded mt-5 mb-7 border-gray-300 p-3 relative">
      <div class="w-20">
        <img class="flex-none w-full border border-gray-200 rounded" :src="image" :alt="gift" />
      </div>
      <div class="flex-1 ml-4">
        <div class="font-semibold text-primary uppercase text-2xs">
          {{ occasion }}
        </div>
        <div class="text-sm text-gray-500 font-semibold">
          {{ gift }}
        </div>
        <div v-if="option" class="italic" style="font-size: 11px">
          <span class="sr-only">{{ $t("general.option") }}: </span>
          {{ option }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NewCartItem",
  props: {
    gift: {
      type: String,
      required: true,
    },
    image: {
      type: String,
      required: true,
    },
    occasion: {
      type: String,
      required: true,
    },
    option: {
      type: String,
      required: true,
    },
  },
}
</script>
