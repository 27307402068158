const PersonalizesItems = {
  data() {
    return {
      personalize: false,
      personalizing: null,
      dir: "left",
    }
  },

  watch: {
    personalizing(val) {
      const wrapper = document.querySelector("#shared-approval-page-wrapper")
      if (!wrapper) return

      // Can be refactored!
      if (val) {
        wrapper.classList.remove("max-w-3xl")
        wrapper.classList.add("max-w-6xl")
      } else {
        wrapper.classList.add("max-w-3xl")
        wrapper.classList.remove("max-w-6xl")
      }
    },
  },

  methods: {
    startPersonalizing(itemId) {
      this.personalizing = true
      this.dir = "left"
      this.$store.commit("sharedOrder/hideApprovalSidebar")
      this.setState({ attribute: "personalizingItemId", value: itemId })
      this.$nextTick(() => {
        window.scroll({
          top: 0,
          left: 0,
          behavior: "smooth",
        })
      })

      // load the employees state with existing data
      // if there is any
      const existing = this.$store.state.sharedOrder.personalizationData[itemId]
      if (existing) {
        this.loadFromPersonalizationData(existing)
      }
    },
    cancelPersonalizing() {
      this.personalizing = null
      this.dir = "right"
      this.$store.commit("sharedOrder/showApprovalSidebar")
      this.setState({ attribute: "personalizingItemId", value: null })
    },
  },
}

export default PersonalizesItems
