<template>
  <SlideIn dir="right" full>
    <div
      v-show="$store.state.sharedOrder.showingApprovalSidebar"
      class="flex flex-col w-full max-w-sm bg-gray-50 p-12 transform-none"
    >
      <div class="flex flex-col justify-between h-0 flex-1">
        <div class="">
          <a class="block" href="#">
            <Logo class="h-12 text-black" />
          </a>
        </div>
        <div class="">
          <ul class="space-y-8 text-gray-400">
            <StepItem
              v-for="(step, index) in mappedSteps"
              :key="`step-index-${index}`"
              :step="step"
            />
          </ul>
        </div>
        <div class=""></div>
      </div>

      <div>
        <TertiaryButton @click="helpSidebarOn = !helpSidebarOn">
          {{ $t("general.having_trouble") }}
          <span class="ml-1">{{ $t("general.contact_us") }}</span>
        </TertiaryButton>
      </div>

      <help-sidebar v-if="helpSidebarOn" :close="() => (helpSidebarOn = false)"></help-sidebar>
    </div>
  </SlideIn>
</template>

<script>
import SlideIn from "../../../transitions/SlideIn.vue"
import StepItem from "./StepItem.vue"
import HelpSidebar from "../HelpSidebar.vue"
import TertiaryButton from "../../buttons/TertiaryButton.vue"
import Logo from "@/components/icons/Logo.vue"

export default {
  components: {
    SlideIn,
    StepItem,
    TertiaryButton,
    Logo,
    HelpSidebar,
  },
  props: {
    steps: Array,
  },

  data() {
    return {
      helpSidebarOn: false,
    }
  },

  computed: {
    mappedSteps() {
      const activeIndex = this.steps.findIndex(step => step.status === true)

      return this.steps.map((step, index) => ({
        ...step,
        status: index < activeIndex ? "done" : step.status,
      }))
    },
  },
}
</script>
