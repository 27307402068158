<template>
  <Slideover
    :show="show"
    :title="$t('general.edit_personalization')"
    :primary-button="{ text: $t('general.update') }"
    :secondary-button="{ text: $t('general.cancel') }"
    @primary-click="close"
    @secondary-click="close"
    @close="close"
  >
    <form v-if="recipient" @submit.prevent>
      <TextInput
        :label="$t('forms.from')"
        :model-value="recipient.from"
        @update:model-value="$event => updateRecipientData($event, 'from')"
      />
      <TextInput
        :label="$t('forms.to')"
        :model-value="recipient.to"
        @update:model-value="$event => updateRecipientData($event, 'to')"
      />
      <TextInput
        v-if="directlyToRecipient && !isNonDigitalDirectHomeDelivery"
        :label="$t('forms.email')"
        type="email"
        :model-value="recipient.email"
        required
        @update:model-value="$event => updateRecipientData($event, 'email')"
      />
      <div class="grid grid-cols-2 gap-2">
        <MonetaryInput
          v-if="!hasFixedPrice"
          :label="$t('forms.amount')"
          :model-value="recipient.price"
          required
          @update:model-value="$event => updateRecipientData($event, 'price')"
        />
        <SelectHtml
          :model-value="recipient.locale"
          id="locale"
          :label="$t('forms.language')"
          :options="locales"
          required
          :class="{ 'col-span-2': hasFixedPrice }"
          @update:model-value="$event => updateRecipientData($event, 'locale')"
        />
      </div>
      <TextArea
        :id="`message-${id}`"
        :label="$t('forms.message')"
        :max-length="isKadonationGreetingCard || isSendKadonationGreetingCardToVendor ? 600 : 300"
        :assume-enter-as-many-characters="true"
        :model-value="recipient.message"
        @update:model-value="$event => updateRecipientData($event, 'message')"
      />
      <div
        v-if="directlyToRecipient && !isNonDigitalDirectHomeDelivery"
        class="grid grid-cols-2 gap-2"
      >
        <DatePicker
          type="date"
          :format="dateTimeFormat.date"
          :label="$t('forms.delivery_date')"
          :model-value="recipient.delivery_date"
          required
          @input="$event => updateRecipientData($event, 'delivery_date')"
        />
        <DatePicker
          type="time"
          :format="dateTimeFormat.time"
          :label="$t('forms.delivery_time')"
          :model-value="recipient.delivery_time"
          required
          @input="$event => updateRecipientData($event, 'delivery_time')"
        />
      </div>
      <div
        v-if="directlyToRecipient && isNonDigitalDirectHomeDelivery"
        class="grid grid-cols-2 gap-2"
      >
        <TextInput
          :label="$t('forms.recipient_first_name')"
          :model-value="recipient.recipient_first_name"
          required
          @update:model-value="$event => updateRecipientData($event, 'recipient_first_name')"
        />
        <TextInput
          :label="$t('forms.recipient_last_name')"
          :model-value="recipient.recipient_last_name"
          required
          @update:model-value="$event => updateRecipientData($event, 'recipient_last_name')"
        />
      </div>
      <div v-if="!isVendorGift">
        <p class="py-2 font-bold">{{ $t("orders.video") }}</p>
        <video-upload
          ref="videoUpload"
          input-classes="w-11/12"
          dropzone-classes="w-full"
          :model-value="recipient.thankyou_video"
          @input="data => updateRecipientData(data, 'thankyou_video')"
        />
      </div>

      <!-- Address fields -->
      <div v-if="isNonDigitalDirectHomeDelivery && showAddressFields" class="mt-4">
        <TextInput
          :label="$t('forms.street')"
          :model-value="recipient.street"
          required
          @update:model-value="$event => updateRecipientData($event, 'street')"
        />
        <TextInput
          :label="$t('forms.number')"
          :model-value="recipient.street_number"
          required
          @update:model-value="$event => updateRecipientData($event, 'street_number')"
        />
        <TextInput
          :label="$t('forms.bus')"
          :model-value="recipient.bus"
          @update:model-value="$event => updateRecipientData($event, 'bus')"
        />
        <TextInput
          :label="$t('forms.zipcode')"
          :model-value="recipient.zipcode"
          required
          @update:model-value="$event => updateRecipientData($event, 'zipcode')"
        />
        <TextInput
          :label="$t('forms.city')"
          :model-value="recipient.city"
          required
          @update:model-value="$event => updateRecipientData($event, 'city')"
        />
        <SelectHtml
          :model-value="recipient.country"
          id="country"
          :label="$t('forms.country')"
          :options="countries"
          required
          @update:model-value="$event => updateRecipientData($event, 'country')"
        />
      </div>

      <p v-if="!showAddressFields">{{ $t("employees.address_info_hidden") }}</p>
    </form>
  </Slideover>
</template>

<script>
import SelectHtml from "@/components/elements/inputs/SelectHtml.vue"
import TextArea from "@/components/elements/inputs/TextArea.vue"
import TextInput from "../elements/inputs/TextInput.vue"
import DatePicker from "@/components/forms/DatePicker.vue"
import VideoUpload from "@/components/employees/VideoUpload.vue"
import { mapActions, mapGetters, mapMutations, mapState } from "vuex"
import MonetaryInput from "../elements/inputs/MonetaryInput.vue"
import Slideover from "@/components/elements/slideovers/Slideover.vue"

export default {
  props: {
    id: {
      type: String,
      default: Math.random().toString(36).substr(2, 5),
    },
    show: Boolean,
    fieldToFocus: String,
    showAddressFields: Boolean,
  },
  components: {
    TextArea,
    TextInput,
    Slideover,
    SelectHtml,
    DatePicker,
    VideoUpload,
    MonetaryInput,
  },
  computed: {
    ...mapState("product", ["directlyToRecipient"]),
    ...mapGetters("recipients", { recipient: "recipientToEdit" }),
    ...mapGetters("product", [
      "isNonDigitalDirectHomeDelivery",
      "isVendorGift",
      "hasFixedPrice",
      "isKadonationGreetingCard",
      "isSendKadonationGreetingCardToVendor",
    ]),
    countries() {
      return this.$store.state.product.countries
    },
    locales() {
      return this.$store.state.product.locales.length
        ? this.$store.state.product.locales
        : this.$store.state.sharedOrder.locales
    },
  },
  mounted() {
    if (this.fieldToFocus) {
      setTimeout(() => {
        const el = document.querySelector(`#${this.fieldToFocus}`)
        el && el.focus()
      }, 100)
    }
  },
  methods: {
    ...mapMutations("recipients", ["setRecipientData"]),
    ...mapActions("recipients", ["cancelEdit"]),
    updateRecipientData(value, attribute) {
      this.setRecipientData({
        id: this.recipient.id,
        attribute,
        value,
      })
    },
    close() {
      this.$emit("close")
      this.$nextTick(() => this.cancelEdit())
    },
  },
}
</script>
