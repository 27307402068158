<template>
  <OnClickOutside class="relative w-full max-w-md mb-5" @trigger="() => (focused = false)">
    <InputLabel id="group-message">
      {{ $t("forms.message") }}
    </InputLabel>

    <textarea
      id="group-message"
      ref="smallTextArea"
      name="group-message"
      class="mt-0.5 h-12 block w-full p-3 text-xs transition border placeholder-gray-300 border-gray-300 hover:border-gray-400 focus:ring-1 focus:outline-none focus:border-blue-300 focus:ring-blue-200 rounded"
      :placeholder="$t('forms.enter_message_here')"
      @focus="focused = true"
      @input="handleInput"
    />

    <p v-if="focused" class="absolute left-0 -bottom-5">
      <ActionLink class="text-2xs" @click="openInEditor">
        {{ $t("forms.open_in_editor") }}
      </ActionLink>
    </p>

    <!-- Mass update editing slider  -->
    <SlideOver
      :show="slideOverOpen"
      :title="$t('general.edit_personalization')"
      :primary-button="{ text: $t('general.update') }"
      @primary-click="handleUpdate"
      :secondary-button="{ text: $t('general.close') }"
      @secondary-click="handleClose"
      @close="handleClose"
    >
      <template #introText>
        {{ $t("general.mass_update_message_description") }}
      </template>
      <TextArea
        id="textArea"
        v-model="message"
        :max-length="isKadonationGreetingCard || isSendKadonationGreetingCardToVendor ? 600 : 300"
        :label="$t('forms.message')"
        rows="10"
      />
    </SlideOver>
  </OnClickOutside>
</template>

<script>
import { mapGetters, mapMutations, mapState } from "vuex"
import { OnClickOutside } from "@vueuse/components"
import TextArea from "@/components/elements/inputs/TextArea.vue"
import InputLabel from "@/components/elements/inputs/_base/InputLabel.vue"
import SlideOver from "@/components/elements/slideovers/Slideover.vue"
import PrimaryButton from "@/components/elements/buttons/PrimaryButton.vue"
import TertiaryButton from "@/components/elements/buttons/TertiaryButton.vue"
import captureUserEvent from "@/helpers/captureUserEvent"
import ActionLink from "@/components/elements/links/ActionLink.vue"

export default {
  components: {
    InputLabel,
    TextArea,
    PrimaryButton,
    OnClickOutside,
    SlideOver,
    TertiaryButton,
    ActionLink,
  },
  data() {
    return {
      message: "",
      focused: false,
      slideOverOpen: false,
    }
  },
  computed: {
    ...mapState("product", ["locales", "directlyToRecipient", "settings"]),
    ...mapGetters("product", ["isKadonationGreetingCard", "isSendKadonationGreetingCardToVendor"]),
  },
  watch: {
    slideOverOpen(val) {
      if (val) {
        // $nextTick isn't working for some reason.
        setTimeout(() => {
          const el = document.getElementById("textArea")
          if (el) el.focus()
        }, 200)
      }
    },
  },
  methods: {
    ...mapMutations("recipients", ["massUpdate"]),
    openInEditor() {
      this.slideOverOpen = true

      // track user activity
      captureUserEvent("Ordering - Opening Larger Message Panel", {
        type: "batch",
      })
    },
    handleInput(event) {
      const value = event.target.value

      this.message = value
      this.massUpdate({ attribute: "message", value })
    },
    handleUpdate() {
      this.massUpdate({ attribute: "message", value: this.message })
      this.handleClose()

      this.message = ""
      this.$refs.smallTextArea.value = ""
    },
    handleClose() {
      this.slideOverOpen = false
    },
  },
}
</script>
