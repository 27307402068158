const HasPrices = {
  computed: {
    unequalTotals() {
      return this.quotationTotal !== this.orderGrandTotal
    },
    total() {
      return this.orderGrandTotal - this.vat
    },
    quotationTotal() {
      if (!this.quotation) {
        return null
      }

      return this.quotation.grand_total
    },
    orderGrandTotal() {
      const total = this.order.grand_total

      return parseFloat(total)
    },
    vat() {
      return parseFloat(this.order.vat)
    },
    handlingFee() {
      return parseFloat(this.order.handling_fee)
    },
    homeDeliveryFee() {
      return this.order.home_delivery_fee ? parseFloat(this.order.home_delivery_fee) : 0
    },
    shippingFee() {
      return this.order.shipping_fee ? parseFloat(this.order.shipping_fee) : 0
    },
  },
}

export default HasPrices
