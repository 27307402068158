<template>
  <div class="pr-8 py-5">
    <div class="flex space-x-5">
      <div class="flex-shrink-0">
        <img
          class="w-30 rounded border border-gray-200"
          :src="gift.picture"
          :alt="gift.name[userLang]"
        />
      </div>

      <div class="flex-1">
        <div v-if="!minimal" class="flex justify-between items-center">
          <div class="leading-tight">
            <p class="text-2xs font-semibold uppercase text-pink-300 tracking-wider">
              {{ item.occasion.name[userLang] }}
            </p>
            <h3 class="font-title font-extrabold">
              {{ gift.name[userLang] }}
            </h3>
          </div>
          <div class="text-right">
            <p
              class="font-title font-extrabold"
              :class="item.voucher_discount ? 'text-sm text-gray-400 line-through' : 'text-lg'"
            >
              {{ currencyEuro(price) }}
            </p>
            <p v-if="item.voucher_discount" class="text-lg font-title font-extrabold">
              {{ currencyEuro(price * (1 - (item.voucher_discount || 0))) }}
            </p>
          </div>
        </div>

        <div class="flex">
          <dl class="mt-4 w-2/3 grid grid-cols-3 gap-x-8 gap-y-3">
            <div v-if="item.option">
              <dt class="text-2xs font-semibold text-gray-400 uppercase">
                {{ $t("general.option") }}
              </dt>
              <dd class="text-sm text-gray-500">
                {{ item.option.name[userLang] }}
              </dd>
            </div>
            <div>
              <dt class="text-2xs font-semibold text-gray-400 uppercase">
                {{ $t("general.value") }}
              </dt>
              <dd class="text-sm text-gray-500">
                <span
                  v-for="_item in quantities"
                  :key="_item.quantity + Math.random()"
                  class="block"
                >
                  {{ _item.quantity }} x
                  {{ currencyEuro(parseFloat(_item.value)) }}
                </span>
              </dd>
            </div>
            <div>
              <dt class="text-2xs font-semibold text-gray-400 uppercase">
                {{ $t("general.handling_fee") }}
              </dt>
              <dd class="text-sm text-gray-500">
                <span>{{ currencyEuro(handlingFee) }}</span>
              </dd>
            </div>
            <div v-if="isDigital && item.is_directly_to_recipient" class="col-span-3">
              <dt class="text-2xs font-semibold text-gray-400 uppercase">
                {{ $t("shared-order-approval.delivery") }}
              </dt>
              <dd class="flex items-center text-sm text-gray-500">
                <p>
                  {{ $t("shared-order-approval.direct_to_recipient") }}
                </p>
                <VTooltip trigger="hover" class="ml-2 flex items-center">
                  <QuestionCircleSolidIcon
                    class="h-5 w-5 text-gray-400 hover:text-gray-500 transition"
                  />
                  <template #popper>
                    <p class="max-w-sm text-center">
                      {{ $t("shared-order-approval.digital_direct_to_recipient_explanation") }}
                    </p>
                  </template>
                </VTooltip>
              </dd>
            </div>
            <div v-if="!isDigital && item.is_directly_to_recipient" class="col-span-3">
              <dt class="text-2xs font-semibold text-gray-400 uppercase">
                {{ $t("general.delivery_address") }}
              </dt>
              <dd class="flex items-center text-sm text-gray-500">
                <p>
                  {{ $t("shared-order-approval.direct_to_recipient") }}
                </p>
                <VTooltip trigger="hover" class="ml-2 flex items-center">
                  <QuestionCircleSolidIcon
                    solid
                    class="h-5 w-5 text-gray-400 hover:text-gray-500 transition"
                  />
                  <template #popper>
                    <p class="max-w-sm text-center">
                      {{ $t("shared-order-approval.physical_direct_to_recipient_explanation") }}
                    </p>
                  </template>
                </VTooltip>
              </dd>
            </div>
          </dl>

          <template v-if="!minimal">
            <SlideIn>
              <div
                v-show="personalize && item.gift.personalizable"
                class="w-1/3 flex items-start justify-end mt-4"
              >
                <SecondaryButton
                  compact
                  class="border-pink-300 text-pink-300 hover:bg-pink-200 hover:bg-opacity-50"
                  @click="$emit('personalize', item.id)"
                >
                  {{ $t("shared-order-approval.personalize") }}
                </SecondaryButton>
              </div>
            </SlideIn>

            <div v-show="dataOnly" class="w-1/3 flex items-start justify-end mt-4">
              <SecondaryButton
                compact
                class="border-pink-300 text-pink-300 hover:bg-pink-200 hover:bg-opacity-50"
                @click="$emit('show-data', item.id)"
              >
                {{ $t("shared-order-approval.view_data") }}
              </SecondaryButton>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex"

import QuestionCircleSolidIcon from "../../icons/QuestionCircleSolidIcon.vue"
import SlideIn from "../../transitions/SlideIn.vue"
import SecondaryButton from "../../elements/buttons/SecondaryButton.vue"
import { currencyEuro } from "@/helpers/currency-helpers"

export default {
  props: {
    personalize: {
      type: Boolean,
      default: false,
    },
    dataOnly: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      required: true,
    },
    minimal: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return { currencyEuro }
  },
  computed: {
    ...mapState("sharedOrder", ["order"]),
    gift() {
      return this.item.gift.default ?? this.item.gift
    },
    firstFourQuantities() {
      return this.quantities.slice(0, 4)
    },
    quantities() {
      return Object.entries(
        this.item.messages.reduce((a, b) => {
          ;(a[b["value"]] = a[b["value"]] || []).push(b)
          return a
        }, {}),
      ).map(entry => ({ value: entry[0], quantity: entry[1].length }))
    },
    handlingFee() {
      return parseFloat(this.item.handling_fee || 0)
    },
    price() {
      return this.item.messages.map(({ value }) => parseFloat(value)).reduce((a, b) => a + b, 0)
    },
    isDigital() {
      //  Digital Kadonation Vouchers not to be sent for fulfillment.
      return (
        this.item.gift.is_kadonation_voucher &&
        this.item.option?.kadonation_voucher_type === "digital"
      )
    },
    deliveryAddress() {
      if (!this.order.address) return ""
      const { address } = this.order
      return `${address.street} ${address.number}${address.bus ? "/" + address.bus : ""}, ${
        address.zip_code
      } ${address.city}`
    },
  },

  components: {
    SlideIn,
    QuestionCircleSolidIcon,
    SecondaryButton,
  },
}
</script>
