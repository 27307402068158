<template>
  <div class="relative mb-2 flex items-center justify-between">
    <div class="absolute inset-0 flex items-center">
      <hr class="border-gray-200 w-full" />
    </div>
    <h2 class="relative inline font-bold uppercase text-primary pr-4 bg-white">
      <slot></slot>
    </h2>
    <div>
      <slot name="action"></slot>
    </div>
  </div>
</template>

<script>
export default {}
</script>
