<template>
  <li class="flex items-start">
    <div class="flex justify-center mr-4 w-5">
      <span
        v-show="status == 'todo'"
        class="block w-2 h-2 rounded-full ring-2 ring-offset-2 ring-gray-300 bg-white mt-2"
      ></span>
      <span
        v-show="status == 'active'"
        class="block w-2 h-2 rounded-full ring-2 ring-offset-2 ring-primary bg-primary mt-2"
      ></span>
      <svg
        v-show="status == 'done'"
        xmlns="http://www.w3.org/2000/svg"
        class="h-5 w-5 text-green-300 mt-px"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          fill-rule="evenodd"
          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
          clip-rule="evenodd"
        />
      </svg>
    </div>
    <div
      class="w-full items-center font-title font-semibold"
      :class="{ 'text-primary': status == 'active' }"
    >
      {{ $t(`general.${step.step}`) }}
      <span class="block uppercase font-text font-semibold text-xs text-gray-300">
        <slot name="subtext"></slot>
      </span>
    </div>
  </li>
</template>

<script>
export default {
  props: {
    step: Object,
  },

  computed: {
    status() {
      switch (this.step.status) {
        case "done":
          return "done"
        case true:
          return "active"
      }

      return "todo"
    },
  },
}
</script>
