<template>
  <tr class="relative transition group">
    <td class="p-4 text-sm font-medium text-gray-900 whitespace-nowrap">
      {{ recipient.from || "&mdash;" }}
    </td>
    <td class="p-4 text-sm font-medium text-gray-900 whitespace-nowrap">
      {{ recipient.to || "&mdash;" }}
    </td>
    <td v-if="directlyToRecipient" class="p-4 text-sm text-gray-500 whitespace-nowrap">
      {{ recipient.email }}
    </td>
    <td class="p-4 text-sm text-gray-500 whitespace-nowrap">€ {{ recipient.price }}</td>
    <td class="p-4 text-sm text-gray-500 whitespace-nowrap">
      {{ $t(languages[recipient.locale]) }}
    </td>
    <td class="p-4 text-sm text-gray-500 whitespace-nowrap">
      {{ recipient.message || "&mdash;" }}
    </td>
    <td
      v-if="directlyToRecipient && !$store.getters['product/isNonDigitalDirectHomeDelivery']"
      class="p-4 text-sm text-gray-500"
    >
      {{ recipient.delivery_date }}
    </td>
    <td
      v-if="directlyToRecipient && !$store.getters['product/isNonDigitalDirectHomeDelivery']"
      class="p-4 text-sm text-gray-500"
    >
      {{ recipient.delivery_time }}
    </td>
    <td
      v-if="directlyToRecipient && $store.getters['product/isNonDigitalDirectHomeDelivery']"
      class="p-4 text-sm text-gray-500"
    >
      {{ recipient.recipient_first_name }}
    </td>
    <td
      v-if="directlyToRecipient && $store.getters['product/isNonDigitalDirectHomeDelivery']"
      class="p-4 text-sm text-gray-500"
    >
      {{ recipient.recipient_last_name }}
    </td>
    <td
      v-if="isHomeDelivery && canManageEmployees"
      class="p-4 text-sm text-gray-500 whitespace-nowrap"
    >
      {{ recipient.street }}
    </td>
    <td
      v-if="isHomeDelivery && canManageEmployees"
      class="p-4 text-sm text-gray-500 whitespace-nowrap"
    >
      {{ recipient.street_number }}
    </td>
    <td
      v-if="isHomeDelivery && canManageEmployees"
      class="p-4 text-sm text-gray-500 whitespace-nowrap"
    >
      {{ recipient.bus }}
    </td>
    <td
      v-if="isHomeDelivery && canManageEmployees"
      class="p-4 text-sm text-gray-500 whitespace-nowrap"
    >
      {{ recipient.zipcode }}
    </td>
    <td
      v-if="isHomeDelivery && canManageEmployees"
      class="p-4 text-sm text-gray-500 whitespace-nowrap"
    >
      {{ recipient.city }}
    </td>
    <td
      v-if="isHomeDelivery && canManageEmployees"
      class="p-4 text-sm text-gray-500 whitespace-nowrap"
    >
      {{ country }}
    </td>
    <td v-if="settings.detailed_invoice === 'true'" class="p-4 text-sm text-gray-500 last-column">
      {{ recipient.detailed_reference }}
    </td>
    <td
      v-for="(field, index) in customerFields"
      :key="`customer-field-${index}`"
      class="p-4 text-sm font-medium text-gray-900 whitespace-nowrap"
    >
      {{ recipient.metadata?.[field] }}
    </td>
  </tr>
</template>

<script>
import { useI18n } from "vue-i18n"
import { mapState } from "vuex"

export default {
  props: {
    recipient: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    customerFields: {
      type: Array,
      default: () => [],
    },
    canManageEmployees: Boolean,
  },
  setup() {
    const { t } = useI18n()
    return { t }
  },
  computed: {
    ...mapState("sharedOrder", ["homeDelivery"]),
    ...mapState("product", ["locales", "directlyToRecipient", "settings"]),
    languages() {
      const languages = this.locales.length ? this.locales : this.$store.state.sharedOrder.locales

      const out = {}
      if (languages && languages.length) {
        languages.forEach(lang => Object.assign(out, { [lang.id]: lang.name }))
      }

      return out
    },
    countries() {
      return this.$store.state.product.countries
    },
    country() {
      if (!this.countries) return this.recipient.country

      const found = this.countries.find(country => country.id === this.recipient.country)

      return found ? this.t(found.name) : this.recipient.country
    },
    isHomeDelivery() {
      return this.$store.getters["product/isNonDigitalDirectHomeDelivery"] || this.homeDelivery
    },
  },
}
</script>
