/* eslint-disable no-undef */

import "./bootstrap"
import i18n from "./i18n"
import store from "./store"
import Toastify from "toastify-js"
import { createPinia } from "pinia"
import * as Sentry from "@sentry/vue"
import * as globals from "./helpers/globals"
import components from "./global-components"
import { zRoute } from "./helpers/route-helpers"
import { createApp } from "vue/dist/vue.esm-bundler"
import {
  // Directives
  VTooltip,
  // Components
  Tooltip,
} from "floating-vue"
import "floating-vue/dist/style.css"
import { setThemeStyles } from "./helpers/setThemeStyles"

const app = createApp({})

const pinia = createPinia()

window.Toastify = Toastify

const setupGlobals = obj => {
  for (const key in obj) {
    app.config.globalProperties[key] = obj[key]
  }
}
app.config.globalProperties.zRoute = zRoute

const setupGlobalComponents = obj => {
  for (const key in obj) {
    app.component(key, obj[key])
  }
}
setupGlobalComponents(components)
setupGlobals(globals)
setThemeStyles()

// Sentry silences the errors (prevents them to be logged to the console) by default so make
// sure that the DSN is correct or leave it empty in `.env` file.
// Remember that both `app.js` and `app-inertia.js` is included in the app until the migration
// to inertia is completed. So make sure that sentry setup is identical in both files.
if (import.meta.env.VITE_SENTRY_VUE_DSN) {
  Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_VUE_DSN,
    environment: import.meta.env.VITE_SENTRY_VUE_ENV,
    tracesSampleRate: 0.0,
    denyUrls: [/js-agent\.newrelic\.com/i, /^chrome-extension:\/\//i],
  })
}
app.directive("tooltip", VTooltip)
app.component("VTooltip", Tooltip)

app.use(store).use(pinia).use(i18n)
app.mount("#app")
