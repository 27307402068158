<template>
  <div class="fixed inset-y-0 right-0 w-full max-w-lg z-40 flex justify-center items-center">
    <fade-in duration="500" @after-enter="open = true" @after-leave="close">
      <div
        v-if="open"
        class="bg-gray-400 bg-opacity-75 fixed inset-0 z-40"
        @click.stop="open = false"
      ></div>
    </fade-in>
    <slide-in duration="300" full>
      <div v-if="open" class="bg-white z-50 h-full w-full flex flex-col">
        <div class="flex p-6 justify-between items-center border-b px-6 shadow">
          <p class="text-lg font-bold text-gray-400 font-title">
            {{ $t("general.help") }}
          </p>
          <button @click="open = false">
            <XCircleSolidIcon class="h-5 w-5" />
          </button>
        </div>
        <div class="px-6 py-4 bg-gray-100 flex-grow space-y-8 overflow-y-auto">
          <div class="space-y-2 px-6">
            <p class="text-base font-bold">{{ $t("general.order_info") }}</p>
            <OrderInfo :order="order" />
          </div>

          <div class="px-6 flex justify-between items-center">
            <p class="text-base font-bold">{{ $t("support.chat") }}</p>

            <TertiaryButton onclick="$zopim.livechat.window.show()">
              {{ $t("support.chat-btn") }}
            </TertiaryButton>
          </div>

          <SupportForm :order-id="orderId" />
        </div>
      </div>
    </slide-in>
  </div>
</template>

<script>
import { mapState } from "vuex"

import SlideIn from "../../transitions/SlideIn.vue"
import FadeIn from "../../transitions/FadeIn.vue"
import { XCircleSolidIcon } from "@/components/icons"
import OrderInfo from "@/components/shared-order-approval/elements/OrderInfo.vue"
import SupportForm from "@/components/shared-order-approval/elements/SupportForm.vue"
import TertiaryButton from "../buttons/TertiaryButton.vue"

export default {
  name: "HelpSidebar",
  components: {
    SlideIn,
    FadeIn,
    OrderInfo,
    TertiaryButton,
    XCircleSolidIcon,
    SupportForm,
  },
  props: {
    close: { type: Function, required: true },
  },
  data() {
    return {
      open: true,
    }
  },
  computed: {
    ...mapState("sharedOrder", ["order", "orderItems"]),
    handler() {
      return this.order.user
    },
    orderId() {
      return parseInt(this.order.id)
    },
  },
}
</script>
