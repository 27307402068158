<template>
  <FadeIn>
    <div v-if="!personalizing">
      <div class="mb-15">
        <step-label>{{ $t("shared-order-approval.step", { step }) }}</step-label>
        <h1 class="font-title font-extrabold text-black text-2xl leading-tight mb-2">
          {{ $t("shared-order-approval.step-3.title") }}
        </h1>
        <p class="text-sm text-gray-400">
          {{ $t("shared-order-approval.step-3.subtitle") }}
        </p>
      </div>

      <div class="mt-10">
        <OrderInfo :quotation="quotation" :unequal-totals="unequalTotals" />
      </div>

      <div class="mt-10 divide-y">
        <OrderItem
          v-for="orderItem in data.orderItems"
          :key="orderItem.id"
          :item="orderItem"
          data-only
          @show-data="startPersonalizing"
        />
      </div>

      <div class="mt-10">
        <OrderSummary
          :quotation="quotation"
          :countries="countries"
          :requires-address-info="data.requires_address_info"
          :addresses="addresses"
        />
      </div>
    </div>

    <!-- Don't forget to update Step2 too when this is updated -->
    <PersonalizeOrderItem
      v-else
      :logo="data.logo"
      read-only
      @cancel-personalizing="cancelPersonalizing"
    />
  </FadeIn>
</template>

<script>
import StepLabel from "../elements/text/StepLabel.vue"
import OrderItem from "./elements/OrderItem.vue"
import FadeIn from "../transitions/FadeIn.vue"
import PersonalizeOrderItem from "../shared-order-approval/PersonalizeOrderItem.vue"
import OrderInfo from "../shared-order-approval/elements/OrderInfo.vue"
import OrderSummary from "../shared-order-approval/elements/OrderSummary.vue"
import { mapMutations, mapState } from "vuex"
import HasPrices from "../../mixins/has-prices"
import PersonalizesItems from "./mixins/PersonalizesItems"
import ResolvesSharedData from "../../mixins/resolves-shared-data"

export default {
  mixins: [PersonalizesItems, HasPrices, ResolvesSharedData],

  data() {
    return {
      poModalOpen: false,
    }
  },

  created() {
    this.setState({ attribute: "orderItems", value: this.data.orderItems })
    this.setState({ attribute: "order", value: this.data.order })
    this.setState({
      attribute: "companyEntity",
      value: this.data.companyEntity,
    })
    this.setState({
      attribute: "billingAddress",
      value: this.data.billingAddress,
    })
    this.$store.commit("product/setCountries", this.countries)
  },

  computed: {
    ...mapState("sharedOrder", ["order"]),
    csrf() {
      return document.querySelector('meta[name="csrf-token"]').content
    },
    unequalTotals() {
      return this.quotationTotal !== this.orderGrandTotal
    },
    invoicedTo() {
      const { companyEntity, billingAddress } = this.data

      return {
        name: companyEntity.name,
        vat: companyEntity.vat_number,
        street: billingAddress.street,
        number: billingAddress.number,
        bus: billingAddress.bus,
        zipcode: billingAddress.zip_code,
        city: billingAddress.city,
        country: billingAddress.countryName,
      }
    },
    quotationTotal() {
      if (!this.data.quotation) {
        return null
      }

      return this.data.quotation.grand_total
    },
    total() {
      return this.orderGrandTotal - this.vat
    },
    orderGrandTotal() {
      const total = this.order.grand_total

      return parseFloat(total)
    },
    vat() {
      return parseFloat(this.order.vat)
    },
    order() {
      return this.data.order
    },
    quotation() {
      return this.data.quotation
    },
    addresses() {
      return this.data.addresses
    },
  },

  methods: {
    ...mapMutations("sharedOrder", ["setState"]),
    startPersonalizing(itemId) {
      this.personalizing = true
      this.dir = "left"
      this.$store.commit("sharedOrder/hideApprovalSidebar")
      this.setState({ attribute: "personalizingItemId", value: itemId })

      // load the employees state with existing data
      // if there is any
      // const existing = this.$store.state.sharedOrder.personalizationData[itemId]
      // if (existing) {
      //   this.loadFromPersonalizationData(existing)
      // }
    },
    cancelPersonalizing() {
      this.personalizing = null
      this.dir = "right"
      this.$store.commit("sharedOrder/showApprovalSidebar")
      this.setState({ attribute: "personalizingItemId", value: null })
    },
  },

  components: {
    StepLabel,
    OrderItem,
    FadeIn,
    PersonalizeOrderItem,
    OrderInfo,
    OrderSummary,
  },
}
</script>
