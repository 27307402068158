<template>
  <div class="px-8 py-6 bg-gray-50 border border-gray-200 rounded">
    <div class="flex items-center justify-between">
      <dl class="flex space-x-8">
        <div>
          <dt class="font-bold tracking-wider text-gray-400 uppercase text-2xs">
            {{ $t("shared-order-approval.step-2.order_id") }}
          </dt>
          <dd class="text-sm font-medium text-gray-500">#{{ order.id }}</dd>
        </div>
        <div>
          <dt class="font-bold tracking-wider text-gray-400 uppercase text-2xs">
            {{ $t("general.total_value") }}
          </dt>
          <dd class="text-sm font-medium text-gray-500">
            {{ currencyEuro(parseFloat(order.grand_total)) }}
          </dd>
        </div>
        <div v-if="expectedDeliveryDate">
          <dt class="font-bold tracking-wider text-gray-400 uppercase text-2xs">
            {{ $t("general.expected_delivery") }}
          </dt>
          <dd class="text-sm font-medium text-gray-500">
            {{ expectedDeliveryDate }}
          </dd>
        </div>
      </dl>
      <div class="flex items-center space-x-2">
        <TertiaryButton
          v-if="quotation"
          :href="quotation.download_path"
          target="_blank"
          link
          compact
          is-external-link
          >{{ $t("shared-order-approval.step-2.view_quotation") }}</TertiaryButton
        >
        <p v-if="quotation && unequalTotals" class="text-orange-400">
          <VTooltip trigger="hover" class="flex items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                clip-rule="evenodd"
              />
            </svg>
            <template #popper>
              <p class="max-w-sm text-center">
                {{ $t("shared-order-approval.modified_order_message") }}
              </p>
            </template>
          </VTooltip>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex"
import { DateTime } from "luxon"

import TertiaryButton from "../../elements/buttons/TertiaryButton.vue"
import { currencyEuro } from "@/helpers/currency-helpers"

export default {
  components: {
    TertiaryButton,
  },
  props: {
    quotation: Object,
    unequalTotals: Boolean,
  },
  setup() {
    return { currencyEuro }
  },
  computed: {
    ...mapState("sharedOrder", ["order"]),

    expectedDeliveryDate() {
      const date = this.order.expected_delivery_at

      if (!date) {
        return ""
      }

      return DateTime.fromISO(date).toFormat(import.meta.env.VITE_DATE_FORMAT)
    },
  },
}
</script>
